import React from 'react';

import LayoutWithHeader from 'components/layouts/with-header';
import { LayoutWrapper } from './layout-wrapper';

type MainLayoutProps = {
  children: React.ReactNode;
  hideFooter?: boolean;
};

export function MainLayout(props: MainLayoutProps): JSX.Element {
  return (
    <LayoutWrapper {...props}>
      <LayoutWithHeader {...props} />
    </LayoutWrapper>
  );
}
