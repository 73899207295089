import DispensaryPage from 'src/dispensary/home';
import { MainDispensaryLayout } from 'src/components/layouts/main-dispensary-layout';

import { getStaticPaths } from 'utils/ssr';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };

export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);

DispensaryPage.layout = MainDispensaryLayout;
export default DispensaryPage;
