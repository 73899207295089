import _ from 'lodash';
import React, { memo, useMemo } from 'react';
import styled, { css } from 'styled-components';

import CarouselSlider from 'components/carousel-slider';
import { breakpoints as carouselBreakpoints } from 'components/carousel-slider/helpers';
import CategoryTile from 'components/category-tile';
import { ContentWrapper } from 'components/core';
import EmptyLoadingState from 'components/empty-loading-state';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { Typography } from 'src/typography';
import useTranslation from 'hooks/use-translation';

const cardMargins = {
  nudge: 10,
  smallTablet: 16,
  tablet: 16,
  largeTablet: 20,
  desktop: 20,
};

const labelId = `category-slider-heading`;
const carouselProps = {
  'aria-labelledby': labelId,
};

function CategoriesCarouselSlider({ borderBottom = true, hideHeader = false, isMarketplace = false }) {
  const { dispensary } = useDispensary();
  const { categories, loading } = useDispensaryCategories();
  const { t } = useTranslation();

  const tiles = useMemo(
    () =>
      _.map(categories, (category) => (
        <TileWrapper
          key={category.key}
          margins={cardMargins}
          role='group'
          aria-roledescription='slide'
          aria-label={category.label}
        >
          <CategoryTile category={category} dispensary={dispensary} disableImageAlt />
        </TileWrapper>
      )),
    [categories, dispensary]
  );

  if (!tiles.length && !loading) {
    return null;
  }

  return (
    <EmptyLoadingState isLoading={!dispensary || loading} page='categoriesCarousel'>
      <GroupContainer borderBottom={borderBottom} isOnBackground={isMarketplace}>
        {!hideHeader && (
          <GroupHeader>
            <Typography.Heading tag='h2' size='medium'>
              {t('common.category_plural', 'Categories')}
            </Typography.Heading>
          </GroupHeader>
        )}
        <CarouselSlider carouselProps={carouselProps} isHalfCardDisplay={false} margins={cardMargins}>
          {tiles}
        </CarouselSlider>
      </GroupContainer>
    </EmptyLoadingState>
  );
}

export default memo(CategoriesCarouselSlider);

const TileWrapper = styled.div`
  min-width: 151px;
  min-height: 139px;
  margin-right: ${cardMargins.smallTablet}px;

  &:first-of-type {
    ${({ theme }) => theme.breakpoints.up(`sm`)} {
      padding-left: 5px;
    }

    @media (min-width: ${carouselBreakpoints.desktop}px) {
      padding-left: 0;
    }
  }

  &:last-of-type {
    min-width: 174px;
    padding-right: 20px;
  }

  @media (min-width: ${carouselBreakpoints.tablet}px) {
    margin-right: ${({ margins }) => margins.tablet}px;
  }

  @media (min-width: ${carouselBreakpoints.largeTablet}px) {
    min-width: 184px;
    min-height: 176px;
    margin-right: ${cardMargins.largeTablet}px;

    &:last-of-type {
      min-width: 204px;
    }
  }

  @media (min-width: ${carouselBreakpoints.desktop}px) {
    margin-right: ${cardMargins.desktop}px;
  }
`;

const GroupContainer = styled(ContentWrapper)`
  ${({ isOnBackground, showAllCategories, theme: { breakpoints } }) => css`
    padding: 0 0 40px;
    position: relative;
    max-width: 534.5px;
    margin: 32px auto 0;
    border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid #d7e4eb' : 'none')};

    ${isOnBackground &&
    `
      margin-top: 0;
    `}

    ${breakpoints.up(`md`)} {
      padding-left: 50px;
    }

    @media (min-width: ${carouselBreakpoints.tablet}px) {
      max-width: ${carouselBreakpoints.tablet}px;
    }

    @media (min-width: ${carouselBreakpoints.largeTablet}px) {
      max-width: ${carouselBreakpoints.largeTablet}px;
    }

    @media (min-width: ${carouselBreakpoints.desktop}px) {
      max-width: ${carouselBreakpoints.desktop}px;
    }

    ${breakpoints.up('sm')} {
      padding: 0 0 25px;
    }

    ${showAllCategories && 'padding-bottom: 50px'}
  `}
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
  margin-bottom: 20px;
`;
