import React from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';

import ROUTES from 'shared/routes';
import DispensaryHeader from 'src/dispensary/components/dispensary-header';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { PageNotFoundError } from 'src/errors';

import useUI from 'src/hooks/use-ui';
import { MainLayout } from './main-layout';

export function MainDispensaryLayout({ children }) {
  const { dispensary } = useDispensary();
  const { isDutchieMain } = useUI();
  const isHidden = dispensary?.storeSettings?.stealthMode || dispensary?.status === 'closed';
  const router = useRouter();
  const viewingCategoriesList = _.includes(router.route, ROUTES.CATEGORIES);

  if (!dispensary) {
    return null;
  }

  if (isDutchieMain && isHidden) {
    throw new PageNotFoundError(router.route);
  }

  return (
    <MainLayout hideFooter={viewingCategoriesList}>
      <DispensaryHeader />
      {children}
    </MainLayout>
  );
}
